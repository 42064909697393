module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://peakdieselservices.com.au"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"linkImagesToOriginal":false,"backgroundColor":"transparent","showCaptions":true,"wrapperStyle":"text-align: center; margin: 0 auto; padding: 2rem 0; font-style: italic; background: none;"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
